import MyBonusesMenu from '@common/components/Sidebar/my-bonuses/MyBonusesMenu';
import usePlatformAppStore from '@common/hooks/usePlatformAppStore';
import { isDomainZone } from '@common/utils/lang';
import styled from '@emotion/styled';
import useMessageCounter from '@features/messages/useMessageCounter';
import WalletIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import AccountIcon from '@mui/icons-material/AccountCircleRounded';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MessageIcon from '@mui/icons-material/Message';
import MuiBadge from '@mui/material/Badge';
import Collapse from '@mui/material/Collapse';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import type { SyntheticEvent } from 'react';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { MenuList } from './styled';

const Badge = styled(MuiBadge)`
  & .MuiBadge-badge {
    right: -10px;
    height: 14px;
  }
`;

export default function UserNavigation() {
  const { isSamsungStore} = usePlatformAppStore()

  const messageCount = useMessageCounter();
  const [ open, setOpen ] = useState(false);
  const handleCollapse = (e: SyntheticEvent) => {
    e.stopPropagation();
    setOpen(!open);
  };

  return (
    <MenuList>
      <MenuItem to="/profile" component={Link}>
        <ListItemIcon>
          <AccountIcon color="warning"/>
        </ListItemIcon>
        <Typography>
          <FormattedMessage id="MY_ACCOUNT"/>
        </Typography>
      </MenuItem>
      <MenuItem to="/messages" component={Link}>
        <ListItemIcon>
          <MessageIcon color="warning"/>
        </ListItemIcon>
        <Badge badgeContent={messageCount} color="error">
          <Typography>
            <FormattedMessage id="MY_MESSAGES"/>
          </Typography>
        </Badge>
      </MenuItem>
      {!isSamsungStore && (
        <>
          <MenuItem onClick={handleCollapse}>
            <ListItemIcon>
              <WalletIcon color="warning"/>
            </ListItemIcon>
            <Typography>
              <FormattedMessage id="SCORE"/>
            </Typography>
            {open ? <ExpandLess/> : <ExpandMore/>}
          </MenuItem>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
          >
            <MenuItem to="/deposit" component={Link}>
              <Typography>
                <FormattedMessage id="REFILL"/>
              </Typography>
            </MenuItem>
            <MenuItem to="/withdrawal" component={Link}>
              <Typography>
                <FormattedMessage id="WITHDRAWAL"/>
              </Typography>
            </MenuItem>
            <MenuItem to="/account-history" component={Link}>
              <Typography>
                <FormattedMessage id="ACCOUNT_HISTORY"/>
              </Typography>
            </MenuItem>
            <MenuItem to="/wallets" component={Link}>
              <Typography>
                <FormattedMessage id="ACCOUNT_MANAGEMENT"/>
              </Typography>
            </MenuItem>
          </Collapse>
        </>
      )}
      {isDomainZone('ua') && <MyBonusesMenu/>}
    </MenuList>
  );
}
