import FroalaTypography from '@common/components/FroalaTypography';
import Box from '@mui/material/Box';

const SourceResult = ({ pageData }: any) => {
  return (
    <Box marginTop={2} px={1}>
      <FroalaTypography p={1} dangerouslySetInnerHTML={{ __html: pageData }}/>
    </Box>
  );
};

export default SourceResult;
