import Headline from '@common/components/headline/Headline';
import { getCountryCode, getLanguage } from '@common/utils/lang';
import { useLazyFetchPageQuery } from '@features/cdn/cdnApi';
import Box from '@mui/material/Box';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import SourceResult from './components/SourceResult';

const Source = () => {
  const lang = getLanguage();
  const countryCode = getCountryCode().toLowerCase();
  const [ fetchPage, { data } ] = useLazyFetchPageQuery();

  useEffect(() => {
    const page = 'sources';
    const pageName = page.concat(`_${countryCode}.html`);
    fetchPage({ lang, pageName });
  }, [ countryCode, lang, fetchPage ]);

  return (
    <Box>
      <Headline title={<FormattedMessage id="SOURCES_PAGE"/>}/>

      <SourceResult pageData={data}/>
    </Box>
  );
};

export default Source;
