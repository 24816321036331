import { useAppSelector } from '@common/hooks/store';
import { isSamsungStoreMarket } from '@common/utils/platform';
import { selectAppPlatformAppStore } from '@features/app/slice/appSlice';
import { useMemo } from 'react';
import { shallowEqual } from 'react-redux';

export default function usePlatformAppStore() {
  const platformAppStoreState = useAppSelector(selectAppPlatformAppStore, shallowEqual);
  const platformAppStoreStorage = isSamsungStoreMarket();
  const platformAppStore = platformAppStoreStorage ?? platformAppStoreState;
  
  return useMemo(() => ({
    platformAppStore,
    isSamsungStore: platformAppStore === 'samsung',
    isTelegramStore: platformAppStore === 'telegram'
  }), [platformAppStore]);
}