import { reactLocalStorage } from 'reactjs-localstorage';

export enum AppPlatform {
  android = 'pma',
  ios = 'pmi',
  web = 'pmw',
}

export const isAndroidPlatform = () => {
  const platform = reactLocalStorage.get<AppPlatform>('platform');
  return platform === AppPlatform.android;
};

export const isIosPlatform = () => {
  const platform = reactLocalStorage.get<AppPlatform>('platform');
  return platform === AppPlatform.ios;
};

export const isWebPlatform = () => {
  const platform = reactLocalStorage.get<AppPlatform>('platform');
  return !(platform === AppPlatform.android || platform === AppPlatform.ios);
};

export const isSamsungStoreMarket = () => {
  return reactLocalStorage.get<string>('store');
}